import { IBackgroundMusic } from "@/ts/sounds/IBackgroundMusic";

// We need to use setInterval and promise to wait for the user first interaction(https://developer.chrome.com/blog/autoplay/)
export class BackgroundMusic implements IBackgroundMusic {
  private audio: HTMLAudioElement = new Audio();
  private tryToPlay: number = 0;

  constructor(
    public src: string = "",
    public interval: number = 500,
    public isLoop: boolean = true
  ) {}

  initMusic() {
    if (!this.src) {
      return;
    }

    this.audio.src = require(`@/assets/media/${this.src}`);

    this.audio.loop = this.isLoop;
  }

  startPlaying() {
    this.tryToPlay = setInterval(() => {
      const playPromise = this.audio.play();

      if (playPromise !== null) {
        playPromise
          .then(() => {
            clearInterval(this.tryToPlay);
          })
          .catch(() => {});
      }
    }, 500);
  }

  stopPlaying() {
    clearInterval(this.tryToPlay);
    this.audio.currentTime = 0;
    this.audio.pause();
  }
}
