


























import { defineComponent } from "@vue/composition-api";
import { ProgramSelectorItem } from "@/ts/ProgramSelectorItem";

export default defineComponent({
  components: {},

  props: {},

  setup() {
    const programs: ProgramSelectorItem[] = [
      new ProgramSelectorItem(
        "módulos de Blending",
        "#e97100",
        require("@/assets/kids/10.jpg"),
        "/blending"
      ),
      new ProgramSelectorItem(
        "módulos de Palabras",
        "#d21e0f",
        require("@/assets/kids/3.jpg"),
        "/palabras"
      )
    ];

    return {
      programs
    };
  }
});
