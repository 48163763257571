






















































import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  components: {},

  props: {
    index: Number,
    module: Object
  },

  setup(props, { emit }) {
    const progress = props.module?.progress;
    const isCompleted = computed(() => progress.completed === progress.total);
    const isInProgress = computed(() => progress.completed > 0 && !isCompleted.value);
    const isDisabled = computed(() => progress.completed === 0);

    function onClick() {
      emit("actions:card-click", props.module);
    }

    return {
      isCompleted,
      isInProgress,
      isDisabled,
      onClick
    };
  }
});
