




















import { computed, onUnmounted } from "@vue/composition-api";

import { useRouter } from "@/ts/composition/vue-router";

import { BackgroundMusic } from "@/ts/sounds/BackgroundMusic";

import { useBlendingStore } from "@/store/useBlendingStore";

import ModuleCard from "@/components/ModuleCard.vue";
import EarnedStars from "@/components/EarnedStars.vue";
import ThePageHeader from "@/components/singletons/ThePageHeader.vue";

export default {
  components: {
    ModuleCard,
    EarnedStars,
    ThePageHeader
  },

  setup() {
    // Fields
    const blendingStore = useBlendingStore();
    const router = useRouter();
    const stars = blendingStore.starsCount;

    blendingStore.load();

    const completedModules = computed(() => {
      return blendingStore.data.value.filter(e => e.isCompleted);
    });
    const activeModules = computed(() => {
      return blendingStore.data.value.filter(e => e.isInProgress);
    });
    const availableModules = computed(() => {
      return blendingStore.data.value.filter(e => !e.isCompleted && !e.isInProgress);
    });

    // Background music
    const audio = new BackgroundMusic("blending/background-music.m4a");
    audio.initMusic();
    audio.startPlaying();

    // Methods
    function openModule(module: any) {
      router.push({
        name: "blending-lesson",
        params: {
          module: String(module.sequenceNumber),
          segment: "1",
          lesson: "1"
        }
      });
    }

    function stopBackgroundMusic() {
      audio.stopPlaying();
    }

    // Hooks
    onUnmounted(stopBackgroundMusic);

    return {
      modules: blendingStore.data,
      completedModules,
      activeModules,
      availableModules,
      stars,
      openModule
    };
  }
};
