










































































import { defineComponent, computed, ref } from "@vue/composition-api";

import CONSTANTS from "@/constants/index";

import { useRouter } from "@/ts/composition/vue-router";
import { BaseLesson } from "@/ts/domain/BaseLesson";
import { BaseExercise } from "@/ts/domain/exercises/BaseExercise";
import { LessonExerciseFilter } from "@/ts/domain/lessons/LessonExerciseFilter";
import { LessonFinishedData } from "@/ts/domain/lessons/LessonFinishedData";

import { LessonFinishedActionType, SyllableConTypeEnum } from "@/ts/domain/Enums";

import { useBlendingStore } from "@/store/useBlendingStore";
import { useLessonStateStore } from "@/store/useLessonStateStore";

import ThePageHeader from "@/components/singletons/ThePageHeader.vue";
import SegmentProgress from "@/components/lessons/shared/SegmentProgress.vue";

// Lesson types
import GridLesson from "@/components/lessons/GridLesson.vue";
import SelectorSequenceLesson from "@/components/lessons/SelectorSequenceLesson.vue";
import ConcatenatorSequenceLesson from "@/components/lessons/ConcatenatorSequenceLesson.vue";
import LessonHeader from "@/components/lessons/shared/LessonHeader.vue";

export default defineComponent({
  components: {
    ThePageHeader,
    SegmentProgress,
    LessonHeader,
    GridLesson,
    SelectorSequenceLesson,
    ConcatenatorSequenceLesson
  },

  setup() {
    const lessonRef = ref();
    const router = useRouter();
    const blendingStore = useBlendingStore();
    const lessonState = useLessonStateStore(true);

    lessonState.load();

    const totalSegments = computed(() => {
      return lessonState.module.value ? lessonState.module.value.segments.length : 0;
    });
    const pageName = computed(() => {
      return "Combinaciones: " + (lessonState.module.value ? lessonState.module.value.name : "");
    });

    // Functions
    async function returnToHome() {
      await terminateLesson();

      router.push({ name: "blending" });
    }

    async function repeatModule() {
      await terminateLesson();

      router
        .push({
          name: "blending-lesson",
          params: {
            module: String(lessonState.module.value?.sequenceNumber),
            segment: "1",
            lesson: "1"
          }
        })
        .catch(err => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes("Avoided redundant navigation to current location")
          ) {
            // This catch improving "Avoided redundant navigation to current location" error(when navigation to same route but with different query params etc.)
            console.log(err);
          }
        });
    }

    function startLesson(): void {
      lessonState.lesson.value?.onFinished.add(onLessonFinished);
      lessonState.lesson.value?.onFailed.add(onLessonFailed);
      // const filter = new LessonExerciseFilter();
      // filter.keys = ["root", "repasso", "pa", "sa", "la", "le", "pe"];
      // lessonState.exerciseFilter.value = filter;
      lessonRef.value.startLesson();
    }

    function saveProgress(): Promise<any> {
      const lessonId = lessonState.lesson.value?.id || "";
      return blendingStore.markLessonAsComplete(lessonId);
    }

    async function onLessonFinished(lesson: BaseLesson) {
      // TODO: move to domain
      if (lesson.clearExerciseFilter) {
        lessonState.exerciseFilter.value = undefined;
      }
    }

    async function onLessonFailed(
      lesson: BaseLesson,
      wrongAnswers: Set<BaseExercise>,
      conTypeToRepeat?: string
    ) {
      if (conTypeToRepeat) {
        const answers = [];

        switch (conTypeToRepeat) {
          case SyllableConTypeEnum.A:
            answers.push(...CONSTANTS.CON_A_ARRAY);
            break;
          case SyllableConTypeEnum.E:
            answers.push(...CONSTANTS.CON_E_ARRAY);
            break;
          case SyllableConTypeEnum.I:
            answers.push(...CONSTANTS.CON_I_ARRAY);
            break;
          case SyllableConTypeEnum.O:
            answers.push(...CONSTANTS.CON_O_ARRAY);
            break;
          case SyllableConTypeEnum.U:
            answers.push(...CONSTANTS.CON_U_ARRAY);
            break;
          default:
            break;
        }
        lessonState.exerciseFilter.value = new LessonExerciseFilter(answers);
      } else if (wrongAnswers && wrongAnswers.size) {
        const answers = ["root", "repasso", ...[...wrongAnswers].map(e => e.text)];

        lessonState.exerciseFilter.value = new LessonExerciseFilter(answers);
      }
    }

    function onContinueClick(): void {
      // TODO: finish saveProgress
      saveProgress().finally(() => {
        openNextLesson();
      });
    }

    function openNextLesson(): void {
      const lesson = lessonState.lesson.value;

      const action = lesson?.isFailed ? lesson?.lessonFailure : lesson?.lessonSuccess;
      const redirected = redirectIfPossible(action);

      if (!redirected) {
        const index = lessonState.findNextLesson();

        // No lesson was found, assume that was the last lesson of the module
        if (index.found) {
          router.push({
            name: "blending-lesson",
            params: {
              module: String(index.module),
              segment: String(index.segment),
              lesson: String(index.lesson)
            }
          });
        } else {
          router.push({ name: "blending" });
        }
      }

      // reset prev lesson to its original state
      lesson?.reset();
    }

    function redirectIfPossible(finishedData: LessonFinishedData | undefined): boolean {
      const doRedirect = hasRedirect(finishedData);

      if (doRedirect) {
        router.push({
          name: finishedData?.action?.target?.url,
          params: finishedData?.action?.target?.params
        });
      }

      return doRedirect;
    }

    function hasRedirect(finishedData: LessonFinishedData | undefined): boolean {
      return (
        !!finishedData &&
        !!finishedData.action &&
        !!finishedData.action.target &&
        finishedData.action.type === LessonFinishedActionType.Redirect
      );
    }

    // We terminate lesson's progress.
    // For example: user can goes back to home page or repeats module from scratch.
    async function terminateLesson(): Promise<void> {
      const lesson = lessonState.lesson.value;

      if (lesson && lesson.isStarted) {
        await lesson.executeTermination();
      }
    }

    return {
      lessonRef,

      ...lessonState,

      totalSegments,
      pageName,

      returnToHome,
      repeatModule,
      startLesson,
      saveProgress,
      onContinueClick
    };
  }
});
