<template>
  <div class="page flex d-flex h-100">
    <div class="ma-auto text-center">
      <h1 class="mb-10 mb-lg-16 text-h4 text-lg-h2">
        <span class="d-block">404</span>
        Page not found
      </h1>

      <v-btn @click="handleRedirect" x-large dark depressed color="#d21e0f" class="logout-button">
        Back to the previous page
      </v-btn>
    </div>
  </div>
</template>

<script>
import { useRouter } from "@/ts/composition/vue-router";

import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup() {
    const router = useRouter();

    function handleRedirect() {
      router.go(-1);
    }

    return {
      handleRedirect
    };
  }
});
</script>
